<template>
    <AppPanel>
        <template #content>
            <div>
                <AppPaginatedGrid
                    ref="grid"
                    :service="service"
                    :actionItems="actionItems"
                    @onToggleMenu="onToggleMenu"
                    subtitle="Integração Senior"
                    tooltip="Sincronização com Senior"
                    :filtrosExtras="filtrosExtras"
                    :hasFiltro="false"
                >
                    <template #incluir-registro>
                        <Button label="Incluir" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openDialogIntegracaoSenior()" />
                    </template>

                    <template #columns>
                        <Column field="id" header="Código" />
                        <Column field="customer.name" header="Cliente" />
                        <Column field="customerBranch.name" header="Unidade" />
                        <Column field="status" header="Status">
                            <template #body="{ data }">
                                <Tag
                                    v-tooltip="getTooltipError(data)"
                                    :style="tagStatusStyle(data.status)"
                                    :value="enumStatusIntegracaoSeniorFuncionario[data.status]"
                                />
                            </template>
                        </Column>
                        <Column field="retroativo" header="Retroativo" />
                        <Column field="createdAt" header="Criado em">
                            <template #body="{ data }">
                                {{ $filters.formatDateOnly(data.createdAt) }}
                            </template>
                        </Column>
                    </template>

                    <template #anotherFilters>
                        <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-between items-center gap-2 lg:w-6">
                            <Chips v-model="filtrosExtras.nomeClientes" placeholder="Pesquisar" class="md:w-10" />
                            <div>
                                <Button
                                    label="Reprocessar"
                                    icon="pi pi-refresh"
                                    @click="reprocessarErrors()"
                                />
                            </div>
                            <div class="field-checkbox md:w-10">
                                <Checkbox id="filtroPossuiErro" :binary="true" v-model="filtrosExtras.possuiErro" />
                                <label for="filtroPossuiErro">Mostrar apenas com erro</label>
                            </div>
                        </div>
                    </template>
                </AppPaginatedGrid>
            </div>
            <DialogIntegracaoSenior ref="dialogIntegracaoSenior" @onLoad="onLoad" />
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { showError, showSuccess, showWarning } from '../../utils/Toast';
import DialogIntegracaoSenior from './components/DialogIntegracaoSenior';
import StatusIntegracaoSeniorFuncionarioEnum from '../../enums/StatusIntegracaoSeniorFuncionarioEnum';
import { getTenant, getToken } from '../../common/storage';
import axios from 'axios';
import { getClientBase } from '../../services/http';

export default {
    components: {
        DialogIntegracaoSenior
    },
    data() {
        return {
            service: null,
            record: {},
            enumStatusIntegracaoSeniorFuncionario: StatusIntegracaoSeniorFuncionarioEnum,
            filtrosExtras: {
                possuiErro: false,
                nomeClientes: []
            },
            actionItems: [
                {
                    label: 'Visualizar',
                    icon: 'pi pi-eye',
                    command: () => this.visualizar(this.record)
                },
                {
                    label: 'Reprocessar',
                    icon: 'pi pi-replay',
                    command: async () => await this.reprocessar(this.record)
                },
                {
                    label: 'Baixar relatório',
                    icon: 'pi pi-download',
                    command: async () => await this.baixarRelatorio(this.record)
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    command: async () => await this.cancelar(this.record)
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/integracao-senior-funcionario');
    },
    watch: {
        'filtrosExtras.possuiErro'() {
            this.$refs.grid.load();
        },
        'filtrosExtras.nomeClientes'() {
            this.$refs.grid.load();
        }
    },
    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        },
        openDialogIntegracaoSenior() {
            this.$refs.dialogIntegracaoSenior.open();
        },
        visualizar(record) {
            this.$router.push(`/integracao-senior/${record.id}`);
        },
        async reprocessar(record) {
            try {
                if (
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.CONCLUIDO ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.ATUALIZANDO_DADOS ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.BUSCANDO_DADOS_SENIOR ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.INTEGRANDO_DADOS_SOC ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.PREPARANDO_DADOS ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.INSERINDO_ITEMS_INTEGRACAO ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.CANCELADO
                ) {
                    showWarning(this.$toast, 'Status inválido para reprocessamento.');
                    return;
                }

                const service = new BaseService('/integracao-senior-funcionario/reprocess');
                service.save(record);
                showSuccess(this.$toast, 'Reprocessamento solicitado. Atualize para acompanhar.');
                await this.$refs.grid.load();
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async reprocessarErrors() {
            try {
                new BaseService('/integracao-senior-funcionario/reprocessErrors');
                showSuccess(this.$toast, 'Reprocessamento solicitado. Atualize para acompanhar.');
                await this.$refs.grid.load();
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async cancelar(record) {
            try {
                if (
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.CANCELADO ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.CONCLUIDO
                ) {
                    showWarning(this.$toast, 'Não é possível cancelar um registro com este status.');
                    return;
                }
                await getClientBase().post(`integracao-senior-funcionario/cancelar-integracao/${record.id}`);
                await this.$refs.grid.load();
                showSuccess(this.$toast, 'Registro cancelado!');
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async baixarRelatorio(record) {
            try {
                axios({
                    url: process.env.VUE_APP_API_BASE_URL + `/integracao-senior-funcionario/generate-csv/${record.id}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        'x-api-key': getTenant()
                    }
                }).then((res) => {
                    const file = window.URL.createObjectURL(new Blob([res.data]));
                    const docUrl = document.createElement('a');
                    docUrl.href = file;
                    docUrl.setAttribute('download', 'relatorio_integracao_senior.xlsx');
                    document.body.appendChild(docUrl);
                    docUrl.click();
                    this.loadingExcel = false;
                });
                showSuccess(this.$toast, 'Relatório gerado com sucesso');
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async onLoad() {
            await this.$refs.grid.load();
        },
        getTooltipError(data) {
            if (
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INTEGRANDO_DADOS_SOC ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_BUSCANDO_DADOS_SENIOR ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_FILTRANDO_DADOS ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INSERINDO_ITEMS_INTEGRACAO ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_PROCESSANDO_ITEMS_INTEGRACAO
            ) {
                return `Não foi possível processar o registro. Erro: ${data.erro}`;
            }

            return '';
        },
        tagStatusStyle(status) {
            if (
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INTEGRANDO_DADOS_SOC ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_BUSCANDO_DADOS_SENIOR ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_FILTRANDO_DADOS ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INSERINDO_ITEMS_INTEGRACAO ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_PROCESSANDO_ITEMS_INTEGRACAO
            ) {
                return {
                    'background-color': 'var(--pink-200)',
                    color: 'var(--pink-800)'
                };
            }

            if (StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.CONCLUIDO) {
                return {
                    'background-color': 'var(--green-300)',
                    color: 'var(--green-900)'
                };
            }

            return {
                'background-color': 'var(--blue-200)',
                color: 'var(--blue-900)'
            };
        }
    }
};
</script>


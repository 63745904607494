const StatusIntegracaoSeniorFuncionarioEnum = Object.freeze({
    CRIADO: 'Criado',
    INTEGRANDO_DADOS_SOC: 'Integrando dados SOC',
    ERRO_INTEGRANDO_DADOS_SOC: 'Erro integrando dados SOC',
    BUSCANDO_DADOS_SENIOR: 'Buscando dados Senior',
    ERRO_BUSCANDO_DADOS_SENIOR: 'Erro buscando dados Senior',
    FILTRANDO_DADOS: 'Filtrando dados',
    ERRO_FILTRANDO_DADOS: 'Erro filtrando dados',
    PREPARANDO_DADOS: 'Preparando dados',
    ATUALIZANDO_DADOS: 'Atualizando dados',
    CONCLUIDO: 'Concluído',
    INSERINDO_ITEMS_INTEGRACAO: 'Inserindo itens integração',
    ERRO_INSERINDO_ITEMS_INTEGRACAO: 'Erro inserindo itens integração',
    ERRO_PROCESSANDO_ITEMS_INTEGRACAO: 'Erro processando itens integração',
    CANCELADO: 'Cancelado'
})

export default StatusIntegracaoSeniorFuncionarioEnum;

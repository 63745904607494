<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '50%' }" header="Incluir" @hide="close">
        <div class="grid">
            <div class="col-8">
                <label for="customer">Cliente</label>
                <DropdownCliente
                    id="customer"
                    v-model="form.customer"
                    class="w-full"
                    :class="{ 'p-invalid': v$.form.customer.$invalid && submitted }"
                    :filtrosExtras="{ ativo: true, integraFuncionarioSenior: true }"
                />
                <small v-if="v$.form.customer.$invalid && submitted" class="p-error">
                    {{ v$.form.customer.required.$message }}
                </small>
            </div>
            <div class="col-4">
                <label for="diasRetroativos">Dias retroativos</label>
                <InputNumber
                    inputId="minmax"
                    v-model="form.diasRetroativos"
                    :min="0"
                    :max="999"
                    integeronly
                    showButtons
                    class="w-full"
                    :class="{ 'p-invalid': v$.form.diasRetroativos.$invalid && submitted }"
                    placeholder="Selecione ou digite"
                />
                <small v-if="v$.form.diasRetroativos.$invalid && submitted" class="p-error">
                    {{ v$.form.diasRetroativos.required.$message }}
                </small>
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" :disabled="confirmLoading" @click="close" />
            <Button label="Confirmar" icon="pi pi-check" :loading="confirmLoading" @click="confirmar" />
        </template>
    </Dialog>
</template>

<script>
import { showError, showSuccess } from '../../../utils/Toast';
import { useVuelidate } from '@vuelidate/core';
import DropdownCliente from '../../clientes/components/DropdownCliente';
import { helpers, required } from '@vuelidate/validators';
import BaseService from '../../../services/BaseService';
import { getClientBase } from '../../../services/http';

export default {
    emits: ['onLoad'],
    components: {
        DropdownCliente
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            isOpen: false,
            confirmLoading: false,
            submitted: false,
            service: null,
            form: {}
        };
    },
    validations() {
        return {
            form: {
                customer: { required: helpers.withMessage('Cliente obrigatório', required) },
                diasRetroativos: { required: helpers.withMessage('Dias retroativos obrigatório', required) }
            }
        };
    },
    mounted() {
        this.service = new BaseService('/integracao-senior-funcionario/start');
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.submitted = false;
            this.form = {};
        },
        async confirmar() {
            try {
                if (!this.$checkPermission('integracao_senior:criar')) {
                    showError('Sem permissão para executar esta ação.');
                    return;
                }
                this.confirmLoading = true;
                this.submitted = true;
                const result = await this.v$.form.$validate();

                if (!result) {
                    this.confirmLoading = false;
                    return;
                }

                const verificar = await getClientBase().get(`/integracao-senior-funcionario/verificar-integracao-andamento/${this.form.customer.id}`);
                if (verificar.data) {
                    showError(this.$toast, '', 'Já existe uma integração em andamento para este cliente.');
                    this.confirmLoading = false;
                    this.submitted = false;
                    return
                }

                this.service.save({
                    customerId: this.form.customer.id,
                    retroativo: this.form.diasRetroativos
                });

                this.$emit('onLoad');
                this.confirmLoading = false;
                this.submitted = false;
                this.close();
                showSuccess(this.$toast, 'Iniciado processamento. Atualize para acompanhar!');
            } catch (error) {
                this.$emit('onLoad');
                this.confirmLoading = false;
                this.submitted = false;
                this.close();
                showError(this.$toast, error);
            }
        }
    }
};
</script>